import useApi from '../../../hooks/useApi';
import { NewAdminResponse } from '../../../models/admin';

const useCreateAdmin = () => {
  const { launchCall, response, isLoading, error, status, errorMessage } =
    useApi<NewAdminResponse>('admin/add-admin');

  const createAdmin = (
    firstName: string,
    lastName: string,
    password: string,
    email: string,
  ) => {
    launchCall({
      method: 'POST',
      body: JSON.stringify({
        firstName,
        lastName,
        password,
        email,
      }),
    });
  };

  return { response, createAdmin, isLoading, error, status, errorMessage };
};

export default useCreateAdmin;

import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import Dropdown, { DropdownOption } from '../../components/Dropdown';
import Pagination from '../../components/Pagination';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import Table, { TableColumn } from '../../components/ui/Table';
import { ListingTable } from '../../models/listings';
import { ListingToListingDataTable } from '../../utils/listings';
import useListing from './useListing/useListing';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as ViewIcon } from '../../assets/eye-fill.svg';
import { ReactComponent as PauseIcon } from '../../assets/pause.svg';
import { ReactComponent as ResumeIcon } from '../../assets/play.svg';
import { ReactComponent as MailIcon } from '../../assets/mail.svg';
import Alert from '../../components/Alert';
import StatusHandler from '../../components/StatusHandler';
import Dialog from '../../components/Dialog';

const DateOrder = [
  {
    id: 'desc',
    value: 'Newest first',
  },
  {
    id: 'asc',
    value: 'Oldest first',
  },
  {
    id: 'avgRating',
    value: 'Popular',
  },
];

const ListingTypes = [
  {
    id: 'Service',
    value: 'Service',
  },
  {
    id: 'Event',
    value: 'Event',
  },
  {
    id: 'Facility',
    value: 'Facility',
  },
];

export const RatingsData = [
  {
    id: '0',
    value: '0',
  },
  {
    id: '1',
    value: '1',
  },
  {
    id: '2',
    value: '2',
  },
  {
    id: '3',
    value: '3',
  },
  {
    id: '4',
    value: '4',
  },
  {
    id: '5',
    value: '5',
  },
];

const ListingStatus = [
  {
    id: 'approved',
    value: 'Approved',
  },
  {
    id: 'required',
    value: 'Required',
  },
];

const Listing = () => {
  const [selectedLists, setSelectedLists] = useState<ListingTable[]>([]);
  const currentAction = useRef<string>();
  const activeListing = useRef<ListingTable>();
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const selectedReviewRef = useRef<ListingTable>();
  const [toggleDialog, setToggleDialog] = useState(false);
  const [userMessage, setUserMessage] = useState('');

  const [filters, setSelectedFilters] = useState<{
    avgRating?: DropdownOption;
    listingType?: DropdownOption;
    searchTitle?: string;
    order?: DropdownOption;
    isApproved?: DropdownOption;
  }>({ order: DateOrder[0] });

  const [listingsData, setListingsData] = useState<{
    headers: TableColumn<ListingTable>[];
    data: ListingTable[];
  }>({ headers: [], data: [] });
  const {
    getListings,
    listings,
    isLoading,
    currentPage,
    totalPages,
    handleDeleteListing,
    handlePauseListing,
    handleResumeListing,
    handleApproveListing,
    sendProviderMessage,
    applyFilters,
    nextPage,
    prevPage,
    error,
  } = useListing();
  const navigate = useNavigate();
  useEffect(() => {
    if (listings) {
      setListingsData(ListingToListingDataTable(listings));
    }
  }, [listings]);

  const removeFromListing = () => {
    const currentListing = activeListing.current;
    if (!currentListing) {
      return;
    }
    handleDeleteListing(currentListing.id);
    const data = listingsData.data.reduce((all, listing) => {
      if (listing.id !== currentListing.id) {
        all.push(listing);
      }
      return all;
    }, [] as ListingTable[]);

    setListingsData(prev => ({ ...prev, data }));
    setDisplayAlert(false);
  };

  const pauseListing = () => {
    const currentListing = activeListing.current;
    if (!currentListing) {
      return;
    }
    let status = {
      status: 'under_review',
    };
    handlePauseListing(currentListing?.id, status);
    setDisplayAlert(false);
  };

  const resumeListing = () => {
    const currentListing = activeListing.current;
    if (!currentListing) {
      return;
    }
    handleResumeListing(currentListing?.id);
    setDisplayAlert(false);
  };

  const approveListing = () => {
    const currentListing = activeListing.current;
    if (!currentListing) {
      return;
    }
    let status = currentListing?.review === 'Required' ? true : false;
    handleApproveListing(currentListing?.id, status);
    setDisplayAlert(false);
  };

  const onDeleteListing = (row: ListingTable) => {
    setDisplayAlert(true);
    setAlertMessage('Are you sure you want to delete it?');
    activeListing.current = row;
    currentAction.current = 'delete';
  };

  const onPauseisting = (row: ListingTable) => {
    setDisplayAlert(true);
    setAlertMessage('Are you sure you want to pause it?');
    activeListing.current = row;
    currentAction.current = 'pause';
  };

  const onResumeListing = (row: ListingTable) => {
    setDisplayAlert(true);
    setAlertMessage('Are you sure you want to resume it?');
    activeListing.current = row;
    currentAction.current = 'resume';
  };

  const onEditListing = (row: ListingTable) => {
    // TBD
    if (!listings) {
      return;
    }
    const listingData = listings.find(dataItem => dataItem._id === row.id);
    navigate(`edit/${row.id}`, { state: listingData });
  };

  const onChangeReviewListing = (row: ListingTable) => {
    setDisplayAlert(true);
    setAlertMessage(
      `Are you sure you want to ${
        row?.review === 'Approved' ? 'prohibit' : 'approve'
      } this listing?`,
    );
    activeListing.current = row;
    currentAction.current = 'approve';
  };

  const handleSortByDate = (option: DropdownOption) => {
    setSelectedFilters(prev => ({ ...prev, order: option }));
    if (option?.id === 'avgRating') {
      applyFilters({ sortBy: option.id, order: 'desc' });
    } else {
      applyFilters({ order: option.id });
    }
  };

  const onViewDetail = (rowId: string) => {
    if (!listings) {
      return;
    }

    const listingData = listings.find(dataItem => dataItem._id === rowId);
    navigate(rowId, { state: listingData });
  };

  const handleToggleDialog = () => {
    setToggleDialog(prev => !prev);
  };

  const handleMessage = (row: ListingTable) => {
    selectedReviewRef.current = row;
    handleToggleDialog();
  };

  const handleSubmitMessage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedReviewRef.current) {
      return;
    }
    let sub = 'Listing has been paused';
    const { provider, providerEmail } = selectedReviewRef.current;
    handleToggleDialog();
    sendProviderMessage(userMessage, providerEmail, provider, sub);
    setUserMessage('');
    selectedReviewRef.current = undefined;
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserMessage(e.target.value);
  };

  const renderActions = (row: ListingTable) => (
    <>
      <>
        <label className="mr-2 relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            value=""
            className="sr-only peer"
            checked={row?.review === 'Approved'}
            onChange={() => onChangeReviewListing(row)}
          />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>
      </>
      <DeleteIcon
        title="Delete listing"
        className="mr-1 fill-[#d63737] cursor-pointer"
        onClick={() => onDeleteListing(row)}
      />
      <ViewIcon
        title="Listing details"
        className="fill-[#ffc904] cursor-pointer"
        onClick={() => onViewDetail(row.id)}
      />
      {row?.status !== 'Under Review' && (
        <PauseIcon
          title="Pause Listing"
          className="fill-[#bc5252] ml-1 cursor-pointer"
          onClick={() => onPauseisting(row)}
        />
      )}
      {row?.status === 'Under Review' && (
        <MailIcon
          title="Mail to provider"
          className="fill-primary-main ml-1 cursor-pointer"
          onClick={() => handleMessage(row)}
        />
      )}
      {row?.status === 'Under Review' && (
        <ResumeIcon
          title="Resume Listing"
          className="fill-primary-main ml-1 cursor-pointer"
          onClick={() => onResumeListing(row)}
        />
      )}
    </>
  );

  const onCloseAlert = () => {
    setDisplayAlert(false);
    activeListing.current = undefined;
    currentAction.current = undefined;
  };

  const handleAlertAction = () => {
    if (!currentAction.current) {
      return;
    }

    switch (currentAction.current) {
      case 'delete':
        removeFromListing();
        return;
      case 'pause':
        pauseListing();
        return;
      case 'resume':
        resumeListing();
        return;
      case 'approve':
        approveListing();
        return;
      default:
        return;
    }
  };
  const handleFiltersByRating = (value: DropdownOption) => {
    setSelectedFilters(prev => ({ ...prev, avgRating: value }));
    applyFilters({ avgRating: [+value.id] });
  };

  const handleFilterByType = (value: DropdownOption) => {
    setSelectedFilters(prev => ({ ...prev, listingType: value }));
    applyFilters({ listingType: [value.id] });
  };

  const handleFilterByStatus = (value: DropdownOption) => {
    setSelectedFilters(prev => ({ ...prev, isApproved: value }));
    applyFilters({ isApproved: value.id === 'approved' ? true : false });
  };

  const handleSearchChange = (value: string) => {
    setSelectedFilters(prev => ({ ...prev, searchTitle: value }));
    applyFilters({ searchTitle: value });
  };

  const handleResetFilters = () => {
    setSelectedFilters({});
    applyFilters({}, true);
  };

  const handleRowSelection = (selected: boolean, row: ListingTable) => {
    if (selected) {
      setSelectedLists(prev => [...prev, row]);
      return;
    }

    setSelectedLists(lists => lists.filter(list => list.id !== row.id));
  };
  const [alertDescription, setAlertDescription] = useState('');
  const handleDeleteMultipleAlertUser = () => {
    setAlertDescription(`Are you sure you want to delete these listings?`);
    setDisplayAlert(true);
    currentAction.current = 'multi-delete';
  };

  return (
    <>
      {toggleDialog && (
        <Dialog
          className="w-[90%] md:w-[600px] h-[420px] justify-around"
          onClose={handleToggleDialog}>
          <div>
            <h1 className="text-2xl text-center mb-3">Send a Message</h1>
            <p>
              The provider will receive this message on their email account.
            </p>
          </div>
          <form className="w-full" onSubmit={e => handleSubmitMessage(e)}>
            <textarea
              required
              value={userMessage}
              onChange={handleMessageChange}
              placeholder="Message for the user"
              className="w-full focus:outline-none mb-4 focus:shadow-none focus:border-none focus:ring-0 text-secondary-main border-none max-h-40 md:max-h-[200px] h-40 bg-[#f6f8f96e] placeholder:text-[#b7b7b7]"
            />
            <Button text="Send" type="submit" />
          </form>
        </Dialog>
      )}
      {displayAlert && (
        <Alert
          onClose={onCloseAlert}
          onConfirm={handleAlertAction}
          title={alertMessage}
        />
      )}
      {selectedLists.length > 1 && displayAlert && (
        <Alert
          onClose={onCloseAlert}
          onConfirm={handleAlertAction}
          title={alertDescription}
        />
      )}
      <div className="flex flex-col w-full h-full overflow-auto px-6 scrollbar-hide">
        <h1 className="text-secondary-main text-[50px] my-6">Listings</h1>
        <div className="w-full flex flex-wrap gap-6 items-center mb-6">
          <Input
            className="w-full min-[480px]:w-[300px]"
            placeholder="Search title or ID"
            value={filters.searchTitle}
            onInput={handleSearchChange}
          />
          <div className="flex flex-wrap gap-6 items-center">
            <Dropdown
              defaultValue={filters?.listingType}
              placeholder="Type"
              className="w-full min-[480px]:w-[200px]"
              options={ListingTypes}
              onSelectOption={handleFilterByType}
            />
            <Dropdown
              defaultValue={filters?.avgRating}
              placeholder="Rating"
              className="w-full min-[480px]:w-[200px]"
              options={RatingsData}
              onSelectOption={handleFiltersByRating}
            />
            <Dropdown
              placeholder="Review"
              className="w-full min-[480px]:w-[200px]"
              defaultValue={filters?.isApproved}
              options={ListingStatus}
              onSelectOption={handleFilterByStatus}
            />
          </div>
          <div className="flex flex-wrap gap-6 items-center w-full min-[480px]:w-auto">
            <Dropdown
              placeholder="Date"
              className="w-full min-[480px]:w-[200px]"
              defaultValue={filters?.order}
              options={DateOrder}
              onSelectOption={handleSortByDate}
            />
          </div>
          <div className="flex flex-wrap gap-6 items-center w-full min-[480px]:w-auto">
            <Button
              className="w-full min-[480px]:w-[200px]"
              text="Reset"
              onClick={handleResetFilters}
            />
            <CSVLink
              className="w-full min-[480px]:w-[200px] text-center font-semibold text-primary-main justify-center"
              data={listingsData.data}
              filename="Sporti_Listings">
              Download CSV
            </CSVLink>
          </div>
        </div>
        {selectedLists.length > 1 && (
          <div className="flex justify-end">
            <button
              className="flex items-center p-2 mr-2"
              onClick={handleDeleteMultipleAlertUser}>
              <DeleteIcon className="fill-[#d63737] mr-1" />
              <span className="text-[#d63737] font-semibold">{`Delete Listings`}</span>
            </button>
          </div>
        )}
        <StatusHandler isLoading={isLoading} error={error}>
          <>
            <Table
              selectable
              selectedRows={selectedLists}
              onRowSelection={handleRowSelection}
              data={listingsData.data}
              columns={listingsData.headers}
              getActions={renderActions}
              currentPage={currentPage}
            />
            <Pagination
              className="my-4"
              currentPage={currentPage}
              totalPages={totalPages}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </>
        </StatusHandler>
      </div>
    </>
  );
};

export default Listing;

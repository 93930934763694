/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSporforyaContext } from '../../../context';
import useApi from '../../../hooks/useApi';
import { ListingResponse, SortListings } from '../../../models/listings';

export type statusType = {
  status: string;
};

const useListing = () => {
  const applyFiltersRef = useRef({ sortBy: 'createdAt' });
  const [errorMessage, setErrorMessage] = useState<string>();
  const { response, isLoading, launchCall, error, status } =
    useApi<ListingResponse>('admin/v2/listings');
  const { launchCall: deleteListing } = useApi('listings');
  const { launchCall: pauseListing } = useApi('change-status');
  const { setToken } = useSporforyaContext();
  const navigate = useNavigate();

  useEffect(() => {
    // Let's make automatically since by the moment
    // there is no other reason to not do it.
    getListings();
  }, []);

  useEffect(() => {
    if (error) {
      if (status === 401) {
        setToken(undefined);
        navigate('/login');
      } else {
        setErrorMessage('Something went wrong, please try again later.');
      }
    }
  }, [error]);

  const handleDeleteListing = (id: string) => {
    deleteListing({ method: 'DELETE' }, undefined, `listing/${id}`);
  };

  const handlePauseListing = async (id: string, status: statusType) => {
    await pauseListing(
      { method: 'PUT', body: JSON.stringify(status) },
      undefined,
      `admin/listings/${id}/change-status`,
    );
    getListings();
  };

  const handleResumeListing = async (id: string) => {
    await pauseListing(
      { method: 'PUT' },
      undefined,
      `admin/listings/${id}/unpause`,
    );
    getListings();
  };

  const handleApproveListing = async (id: string, status: boolean) => {
    await pauseListing(
      { method: 'GET' },
      undefined,
      `admin/listings/${id}/approval?status=${status}`,
    );
    getListings();
  };

  const getListings = (data?: Record<string, any>) => {
    try {
      setErrorMessage(undefined);
      applyFiltersRef.current = { ...applyFiltersRef.current, ...data };
      launchCall({
        method: 'POST',
        body: JSON.stringify(applyFiltersRef.current),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const applyFilters = (sorting: SortListings, reset?: boolean) => {
    if (reset) {
      applyFiltersRef.current = { sortBy: 'createdAt' };
    }
    // Apply filters and reset pagination
    getListings({ ...sorting, skip: 0 });
  };

  const handlePagination = (page: number, offset: number) => {
    const identifier = page - 1;
    getListings({ skip: identifier * offset });
  };

  const nextPage = () => {
    const nextPage = response?.meta.nextPage;
    if (nextPage) {
      handlePagination(nextPage, response.meta.limit);
    }
  };

  const prevPage = () => {
    const prevPage = response?.meta.prevPage;
    if (prevPage) {
      handlePagination(prevPage, response.meta.limit);
    }
  };

  const sendProviderMessage = (message: string, prvEmail: string, name: string, subject: string) => {
    try {
      launchCall(
        {
          method: 'POST',
          body: JSON.stringify({
            email: prvEmail,
            name: name,
            subject: subject,
            emailBody: message,
          }),
        },
        undefined,
        `admin/provider/send-custom-email`,
      );
    } catch (err) {
      console.log(err);
    }
  };

  return {
    getListings,
    nextPage,
    prevPage,
    applyFilters,
    handleDeleteListing,
    handlePauseListing,
    handleResumeListing,
    handleApproveListing,
    sendProviderMessage,
    listings: response?.data,
    totalPages: response?.meta?.totalPages || 0,
    currentPage: response?.meta?.page || 0,
    isLoading,
    error: errorMessage,
  };
};

export default useListing;

import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { User } from '../../models/user';
import UserDetail from '../../components/UserDetail';

const UserDetailsPage = () => {
  const { state, pathname } = useLocation();
  const role = pathname.split('/')[1];
  const userData: User = useMemo(() => {
    return state;
  }, [state]);

  if (!userData) {
    return <Navigate to={'/users'} />;
  }

  return (
    <div>
      <div className="bg-[#f7faff] py-6 px-4 min-h-screen">
        <UserDetail userData={userData} role={role} />
      </div>
    </div>
  );
};

export default UserDetailsPage;

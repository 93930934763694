import { useCallback, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Slider from '../../components/Slide';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as StarIcon } from '../../assets/star.svg';
import { Listing } from '../../models/listings';

const ListingDetailsPage = () => {
  const { state } = useLocation();
  const listingData: Listing = useMemo(() => {
    return state;
  }, [state]);

  const getParsedEventSchedule = useCallback(
    ({ start, end }: { start: string; end: string }) => {
      const startDateTime = new Date(start);
      const endDateTime = new Date(end);

      // Parse Date Range
      const startDate = startDateTime.toLocaleDateString('en-US', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
      });
      const endDate = endDateTime.toLocaleDateString('en-US', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      });
      const dateRange = `${startDate} - ${endDate}`;

      // Parse Time
      const startTime = startDateTime.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
      const endTime = endDateTime.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
      const timeRange = `${startTime} - ${endTime}`;

      return [dateRange, timeRange];
    },
    [],
  );

  if (!listingData) {
    return <Navigate to={'/listings'} />;
  }

  return (
    <div className="listings-details">
      <div className="bg-[#f7faff] py-6 px-4">
        <div className="mx-auto">
          <div className="flex flex-col">
            {listingData.images.length > 0 && (
              <div className="max-w-full lg:pr-8 w-full">
                <Slider slides={listingData.images} />
              </div>
            )}
            <div className="max-w-full w-full pt-6">
              <div>
                <div className="flex items-center border-b pb-4 mb-4">
                  {listingData.provider?.[0].avatar && (
                    <div className="w-20 h-20 pr-4">
                      <img
                        className="w-[100%] rounded-full"
                        src={listingData.provider?.[0].avatar}
                        alt=""
                      />
                    </div>
                  )}
                  <div>
                    <h2 className="text-md text-[#000] font-bold leading-tight">
                      {listingData.title}
                    </h2>
                    <p className="text-sm text-[#000] leading-tight font-semibold">
                      {`${listingData.provider?.[0]?.firstName || ''} ${
                        listingData.provider?.[0]?.lastName || ''
                      }`}
                    </p>
                    <span className="text-xs text-[#000] leading-tight">
                      {listingData.location?.shortName}
                    </span>
                  </div>
                </div>
                {listingData.schedules.map(schedule => {
                  const [dateRange, timeRange] = getParsedEventSchedule(
                    schedule.duration,
                  );
                  return (
                    <div
                      key={schedule._id}
                      className="flex justify-between item-center pb-6">
                      <div className="flex">
                        <CalendarIcon className="w-[20px] h-[20px] text-secondary-main" />
                        <div className="pl-3">
                          <p className="text-sm text-[#000] leading-tight">
                            {dateRange}
                          </p>
                          <p className="text-xs text-[#909193] leading-tight">
                            {timeRange}
                          </p>
                        </div>
                      </div>
                      <p className="text-md font-bold text-[#000]">
                        <span>
                          {new Intl.NumberFormat('en-Us', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(schedule.pricing.price)}
                        </span>
                        <span className="text-xs">
                          {' '}
                          /{schedule.pricing.per}
                        </span>
                      </p>
                    </div>
                  );
                })}
                <div>
                  <div className="flex items-center justify-between">
                    <p className="text-sm flex items-center gap-[5px]">
                      <StarIcon className="w-[20px] h-[20px] text-secondary-main" />
                      <span>{listingData.avgRating}</span>
                      <span className="text-xs ml-[-3px]">/10</span>
                      <span className="bottom-0">
                        ({listingData.totalBookingsCount})
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center gap-4 mt-10">
                    <h3 className="text-md font-bold">
                      Number of Active Bookings :
                    </h3>
                    <p>{listingData?.numberOfActiveBookings}</p>
                  </div>
                  <div className="flex items-center gap-4 mt-4">
                    <h3 className="text-md font-bold">
                      Number of Upcoming Bookings :
                    </h3>
                    <p>{listingData?.numberOfUpcomingBookings}</p>
                  </div>
                  <div className="flex items-center gap-4 mt-4">
                    <h3 className="text-md font-bold">
                      Number of Completed Bookings :
                    </h3>
                    <p>{listingData?.numberOfCompletedBookings}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full my-4">
            <h3 className="py-2 text-md text-[#000] font-bold leading-tight">
              Description
            </h3>
            <p className="text-sm">{listingData.description}</p>
          </div>
          <div className="w-full">
            <div className="pt-4 border-b pb-4 mb-10">
              <div className="w-full">
                <h3 className="mb-4 text-md text-[#000] font-bold leading-tight">
                  Event Details
                </h3>
                <div className="mb-4">
                  <span className="text-xs text-[#909193]">
                    Sport or Activity
                  </span>
                  <p className="text-sm">{listingData.sport}</p>
                </div>
                <div className="mb-4">
                  <span className="text-xs text-[#909193]">
                    Type Of Sport Activity
                  </span>
                  <p className="text-sm">{listingData?.type}</p>
                </div>
                <div className="mb-4">
                  <span className="text-xs text-[#909193]">
                    What’s included
                  </span>
                  <p className="text-sm">
                    {listingData?.specialConditions ?? 'N/A'}
                  </p>
                </div>
              </div>
              <div className="w-full">
                <h3 className="mb-4 text-md text-[#000] font-bold leading-tight">
                  Participants Particulars
                </h3>
                <div className="grid sm:grid-cols-4 grid-cols-2">
                  <div className="mb-4">
                    <span className="text-xs text-[#909193]">Gender</span>
                    <p className="text-sm">{listingData.gender}</p>
                  </div>
                  <div className="mb-4">
                    <span className="text-xs text-[#909193]">Suitable for</span>
                    <p className="text-sm">{listingData.suitable}</p>
                  </div>
                  <div className="mb-4">
                    <span className="text-xs text-[#909193]">
                      Ability Level
                    </span>
                    <p className="text-sm">{listingData.abilityLevel}</p>
                  </div>
                  <div className="mb-4">
                    <span className="text-xs text-[#909193]">Good for</span>
                    <p className="text-sm">{listingData.goodfor.join(', ')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full border-b py-2 mb-10">
              <h3 className="mb-4 text-md text-[#000] font-bold leading-tight">
                Location
              </h3>
              <iframe
                title="Listing Loocation"
                src={`https://maps.google.com/maps?q=${listingData?.location?.description}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                width="400"
                height="300"
                loading="lazy"></iframe>
            </div>
            <div className="w-full">
              <h3 className="mb-4 text-md text-[#000] font-bold leading-tight">
                Facilities, Amenities and Preparation
              </h3>
              <div className="mb-4">
                <span className="text-xs text-[#909193]">
                  Facilities USed for this event
                </span>
                <p className="text-sm">{listingData.facilites.join(', ')}</p>
              </div>
              <div className="mb-4">
                <span className="text-xs text-[#909193]">
                  Amenities Available for this event
                </span>
                <p className="text-sm">{listingData.amenities.join(', ')}</p>
              </div>
              <div className="mb-4">
                <span className="text-xs text-[#909193]">How to prepare?</span>
                <p className="text-sm">{listingData?.instructions ?? 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingDetailsPage;

import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as MenuIcon } from '../../assets/menu.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import useWindowSize from '../../hooks/useWindowSize';

export type SideNavLink = {
  path: string;
  id: string;
  label: string;
  external?: boolean;
};

type Props = {
  links: SideNavLink[];
};

const Sidenav = ({ links }: Props) => {

  const { width } = useWindowSize();
  const [open, setOpen] = useState(width >= 768);

  const toggleSidenav = () => {
    setOpen(prev => !prev);
  };

  useEffect(() => {
    if (width >= 768) {
      setOpen(true);
    }
  }, [width]);

  const fnLogout = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  return (
    <>
      {open && (
        <div className="absolute w-full h-full top-0 left-0 bg-black opacity-70 md:hidden z-40" />
      )}
      {open && (
        <div className="h-full w-[260px] flex flex-col pt-6 px-6 bg-secondary-main z-40 fixed right-0 md:left-0 overflow-auto">
          <button
            className="flex text-white md:hidden justify-end"
            onClick={toggleSidenav}>
            <CloseIcon className="mr-1 fill-white" />
          </button>
          {links.map(({ id, label, path, external }) =>
            !external ? (
              <NavLink
                className="w-fit text-xl text-white py-4"
                to={path}
                key={id}
                style={({ isActive }) => ({
                  fontWeight: isActive ? '700' : '500',
                })}>
                {label}
              </NavLink>
            ) : (
              <a
                rel="noreferrer"
                key={id}
                target={'_blank'}
                href={path}
                className="w-fit text-xl text-white py-4 font-medium">
                {label}
              </a>
            ),
          )}
          <button
            onClick={fnLogout}
            className="w-fit text-xl text-white py-4 font-medium">
            Log out
          </button>
        </div>
      )}
      {!open && (
        <button
          className="absolute top-6 right-6 p-3 rounded-full bg-secondary-main z-50"
          onClick={toggleSidenav}>
          <MenuIcon className="fill-white" />
        </button>
      )}
    </>
  );
};

export default Sidenav;

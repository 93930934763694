import { useEffect, useState } from 'react';
import Dropdown, { DropdownOption } from '../../components/Dropdown';
import StatusHandler from '../../components/StatusHandler';
import Table, { TableColumn } from '../../components/ui/Table';
import { BookingTable } from '../../models/bookings';
import { ListingTable } from '../../models/listings';
import { UserTable } from '../../models/user';
import { BookingToBookingTable } from '../../utils/bookings';
import { ListingToListingDataTable } from '../../utils/listings';
import { UsersToUserDataTable } from '../../utils/users';
import useBookings from '../Bookings/useBookings';
import useUsers from '../Users/useUsers';
import useDashboard from './useDashboard/useDashboard';

const mockedMonths = [
  {
    id: '0',
    value: 'January 2023',
  },
  {
    id: '1',
    value: 'February 2023',
  },
  {
    id: '2',
    value: 'March 2023',
  },
  {
    id: '3',
    value: 'April 2023',
  },
  {
    id: '4',
    value: 'May 2023',
  },
  {
    id: '5',
    value: 'June 2023',
  },
  {
    id: '6',
    value: 'July 2023',
  },
  {
    id: '7',
    value: 'Auguts 2023',
  },
  {
    id: '8',
    value: 'September 2023',
  },
  {
    id: '9',
    value: 'October 2023',
  },
  {
    id: '10',
    value: 'November 2023',
  },
  {
    id: '11',
    value: 'December 2023',
  },
];

const initialTablesData = {
  headers: [],
  data: [],
};

const Dashboard = () => {
  const { metrics, isLoading, error, applyFilters } = useDashboard({
    monthNumber: 0,
  });
  const { bookings } = useBookings({ limit: 10 });
  const { users } = useUsers({ roles: 'user', limit: 10 });
  const { users: providers } = useUsers({ roles: 'provider', limit: 10 });
  const [filter, setSelectedFilter] = useState<DropdownOption>(mockedMonths[0]);
  const [listingsData, setListingsData] = useState<{
    headers: TableColumn<ListingTable>[];
    data: ListingTable[];
  }>(initialTablesData);
  const [bookingsData, setBookingsData] = useState<{
    headers: TableColumn<BookingTable>[];
    data: BookingTable[];
  }>(initialTablesData);
  const [usersData, setUsersData] = useState<{
    headers: TableColumn<UserTable>[];
    data: UserTable[];
  }>(initialTablesData);
  const [providerDate, setProviderData] = useState<{
    headers: TableColumn<UserTable>[];
    data: UserTable[];
  }>(initialTablesData);

  useEffect(() => {
    if (providers) {
      setProviderData(UsersToUserDataTable(providers, true, 'provider'));
    }
  }, [providers]);

  useEffect(() => {
    if (users) {
      setUsersData(UsersToUserDataTable(users, true, 'user'));
    }
  }, [users]);

  useEffect(() => {
    if (bookings) {
      setBookingsData(BookingToBookingTable(bookings, true));
    }
  }, [bookings]);

  useEffect(() => {
    if (metrics) {
      setListingsData(ListingToListingDataTable(metrics.topListings, true));
    }
  }, [metrics]);

  const handleMonthChange = (option: DropdownOption) => {
    applyFilters({ monthNumber: option.id });
    setSelectedFilter(option);
  };


  return (
    <div className="flex-1 w-full h-full overflow-auto px-6 py-6 scrollbar-hide">
      <h1 className="text-4xl text-secondary-main mt-8">General Metrics</h1>
      <StatusHandler isLoading={isLoading} error={error}>
        <>
          <div className="flex justify-end mt-6">
            <Dropdown
              placeholder="Type"
              className="w-full min-[480px]:w-[200px]"
              defaultValue={filter}
              options={mockedMonths}
              onSelectOption={handleMonthChange}
            />
          </div>
          <div className="flex flex-wrap gap-4 justify-center mt-6">
            <div className="flex flex-col items-center mt-11">
              <span className="text-4xl text-primary-main font-semibold">
                {metrics?.totalRevenue}
              </span>
              <span className="text-lg text-secondary-main font-semibold">
                Total Revenue
              </span>
            </div>
            <div className="flex flex-col items-center mt-11">
              <span className="text-4xl text-primary-main font-semibold">
                {metrics?.totalActiveUsers}
              </span>
              <span className="text-lg text-secondary-main font-semibold">
                Total Active Users
              </span>
            </div>
            <div className="flex flex-col items-center mt-11">
              <span className="text-4xl text-primary-main font-semibold">
                {metrics?.totalActiveProviders}
              </span>
              <span className="text-lg text-secondary-main font-semibold">
                Total Active Providers
              </span>
            </div>
            <div className="flex flex-col items-center mt-11">
              <span className="text-4xl text-primary-main font-semibold">
                {metrics?.totalActiveListing}
              </span>
              <span className="text-lg text-secondary-main font-semibold">
                Total Active Listings
              </span>
            </div>
            <div className="flex flex-col items-center mt-11">
              <span className="text-4xl text-primary-main font-semibold">
                {metrics?.totalActiveBooking}
              </span>
              <span className="text-lg text-secondary-main font-semibold">
                Total Active Bookings
              </span>
            </div>
          </div>

          <h1 className="text-3xl text-secondary-main my-12">
            Best Sellings Listings
          </h1>
          <Table data={listingsData.data} columns={listingsData.headers} />
          <h1 className="text-3xl text-secondary-main my-12">New Bookings</h1>
          <Table data={bookingsData.data} columns={bookingsData.headers} />
          <h1 className="text-3xl text-secondary-main my-12">New Users</h1>
          <Table data={usersData.data} columns={usersData.headers} />
          <h1 className="text-3xl text-secondary-main my-12">New Providers</h1>
          <Table data={providerDate.data} columns={providerDate.headers} />
        </>
      </StatusHandler>
    </div>
  );
};

export default Dashboard;

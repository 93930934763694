import { Outlet } from 'react-router-dom';
import Sidenav, { SideNavLink } from '../../components/Sidenav';
import { useSporforyaContext } from '../../context';

const links: SideNavLink[] = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    path: '/',
  },
  {
    id: 'admins',
    label: 'Admins',
    path: '/admins',
  },
  {
    id: 'listings',
    label: 'Listings',
    path: '/listings',
  },
  {
    id: 'providers',
    label: 'Providers',
    path: '/providers',
  },
  {
    id: 'users',
    label: 'Users',
    path: '/users',
  },
  {
    id: 'subscribedUsers',
    label: 'Subscribed Users',
    path: '/subscribed-users',
  },

  {
    id: 'bookings',
    label: 'Bookings',
    path: '/bookings',
  },
  {
    id: 'payments',
    label: 'Payments',
    path: '/payments',
  },
  {
    id: 'review&raiting',
    label: 'Review & Ratings',
    path: '/reviews',
  },
  {
    id: 'reports',
    label: 'Reports',
    path: '/reports',
  },
  {
    id: 'financial',
    label: 'Financial',
    path: 'https://dashboard.stripe.com/login',
    external: true,
  },
  {
    id: 'support',
    label: 'Support',
    path: 'https://app.chatwoot.com/app/login?utm_source=navbar-link',
    external: true,
  },
  {
    id: 'messages',
    label: 'Messages',
    path: 'https://getstream.io/accounts/login/',
    external: true,
  },
  {
    id: 'aws',
    label: 'Email and SMS',
    path: 'https://console.aws.amazon.com/console/home?nc2=h_ct&src=header-signin"',
    external: true,
  },
  {
    id: 'settings',
    label: 'Settings',
    path: '/settings',
  },
];

const AdminPanelPage = () => {
  const { userInfo } = useSporforyaContext();
  return (
    <div className="flex w-full h-full">
      <Sidenav
        links={
          userInfo?.adminRole === 'superadmin'
            ? links
            : links?.filter(link => link?.path !== '/admins')
        }
      />
      <div className="overflow-auto flex-1 md:ml-[260px]">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminPanelPage;

import { format } from 'date-fns';
import { TableColumn } from '../components/ui/Table';
import {
  SubscriberDownladed,
  Subscribers,
  SubscriberTable,
} from '../models/subscriber';

const SubscriberColumn: TableColumn<SubscriberTable>[] = [
  { id: 'serial', label: '#' },
  { id: 'email', label: 'Email' },
  { id: 'name', label: 'Business Name' },
  { id: 'city', label: 'City' },
  { id: 'type', label: 'Type' },
  { id: 'subscribedAt', label: 'Subscribed At' },
  { id: 'actions', label: 'Actions' },
];

export const SubscriberToSubscriberTable = (subscribers: Subscribers[]) => {
  const data: SubscriberTable[] = subscribers.map(
    ({ _id, email, name, city, type, createdAt }, index) => (
      {
      id: _id,
      serial: (index + 1).toString(),
      type: type ?? 'N/A',
      email: email,
      name: name ?? 'N/A',
      city: city ?? 'N/A',
      subscribedAt: format( new Date(createdAt), 'MM-dd-yyy hh:mm a' ),
      actions: 'ACTIONS',
    }
    ),
    
  );
  return { headers: SubscriberColumn, data };
};

export const SubscriberDownloadedData = (subscribers: Subscribers[]) => {
  const data: SubscriberDownladed[] = subscribers.map(
    ({ email, type, createdAt }, index) => ({
      serial: (index + 1).toString(),
      email: email,
      type: type ?? 'N/A',
      subscribedAt: format(new Date(createdAt), 'MM-dd-yyy hh:mm a'),
    }),
  );
  return { data };
};

import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import Dropdown, { DropdownOption } from '../../components/Dropdown';
import Pagination from '../../components/Pagination';
import StatusHandler from '../../components/StatusHandler';
import Button from '../../components/ui/Button';
import Table, { TableColumn } from '../../components/ui/Table';
import { SubscriberDownladed, SubscriberTable } from '../../models/subscriber';
import {
  SubscriberDownloadedData,
  SubscriberToSubscriberTable,
} from '../../utils/subscribers';
import useSubscribedUsers from './useSubscribedUsers';
import { ReactComponent as MailIcon } from '../../assets/mail.svg';
import Alert from '../../components/Alert';

type Props = {
  userRole?: string;
};

const SubscriberTypes = [
  {
    id: 'all',
    value: 'All',
  },
  {
    id: 'provider',
    value: 'Provider',
  },
  {
    id: 'user',
    value: 'User',
  },
];

const SubscribedUsers = ({ userRole = 'Subscriber' }: Props) => {
  const [usersData, setUsersData] = useState<{
    headers: TableColumn<SubscriberTable>[];
    data: SubscriberTable[];
  }>({ headers: [], data: [] });

  const [usersDownloadedData, setUsersDownloadedData] = useState<{
    data: SubscriberDownladed[];
  }>({ data: [] });
  const { users, sendEmail, deleteEmail, isLoading, error } =
    useSubscribedUsers();

  const [currentPage, setCurrentPage] = useState<number | undefined>();
  const [totalPages, setTotalPages] = useState<number | undefined>();

  let currPage = 1;
  let contentPerPage = 20;

  const resetPageSize = () => {
    if (users) {
      setCurrentPage(1);
      setTotalPages(Math.ceil(users.length / contentPerPage));
    }
  };

  const findSlicingIndex = () => {
    const startIndex = currPage * contentPerPage - contentPerPage;
    const endIndex = startIndex + contentPerPage;
    return { startIndex, endIndex };
  };

  useEffect(() => {
    if (users) {
      resetPageSize();
      getPaginatedData();
      setUsersDownloadedData(SubscriberDownloadedData(users));
    }
  }, [users]);

  const [filters, setSelectedFilters] = useState<{
    SubscriberTypes?: DropdownOption;
  }>();

  const handleFilterByType = (value: DropdownOption) => {
    setSelectedFilters(prev => ({ ...prev, SubscriberTypes: value }));
    currPage = 1;
    if (users) {
      let newUsers = users?.filter((item: any) => item?.type === value?.id);
      setCurrentPage(currPage);
      setTotalPages(
        Math.ceil(
          value?.id === 'all'
            ? users.length / contentPerPage
            : newUsers.length / contentPerPage,
        ),
      );
      let { startIndex, endIndex } = findSlicingIndex();
      setUsersData(
        SubscriberToSubscriberTable(
          value?.id === 'all'
            ? users.slice(startIndex, endIndex)
            : newUsers.slice(startIndex, endIndex),
        ),
      );
      setUsersDownloadedData(
        SubscriberDownloadedData(value?.id === 'all' ? users : newUsers),
      );
    }
  };

  const handleResetFilters = () => {
    setSelectedFilters({});
    currPage = 1;
    if (users) {
      resetPageSize();
      let { startIndex, endIndex } = findSlicingIndex();
      const paginatedData = users.slice(startIndex, endIndex);
      setUsersData(SubscriberToSubscriberTable(paginatedData));
      setUsersDownloadedData(SubscriberDownloadedData(users));
    }
  };

  /* Local Pagination feature */

  const getPaginatedData = () => {
    let filter = filters?.SubscriberTypes?.id;
    let { startIndex, endIndex } = findSlicingIndex();
    if (users && filter !== 'provider' && filter !== 'user') {
      const paginatedData = users.slice(startIndex, endIndex);
      setUsersData(SubscriberToSubscriberTable(paginatedData));
    }
    if (users && (filter === 'provider' || filter === 'user')) {
      let newUsers = users?.filter((item: any) => item?.type === filter);
      const paginatedData = newUsers?.slice(startIndex, endIndex);
      setUsersData(SubscriberToSubscriberTable(paginatedData ?? []));
    }
  };

  const nextPage = () => {
    if (currentPage && totalPages && currentPage < totalPages) {
      currPage = currentPage + 1;
      setCurrentPage(currPage);
      getPaginatedData();
    }
  };

  const prevPage = () => {
    if (currentPage && totalPages && currentPage > 1) {
      currPage = currentPage - 1;
      setCurrentPage(currPage);
      getPaginatedData();
    }
  };

  const currentAction = useRef<string>();
  const activeUser = useRef<SubscriberTable>();
  const [alertDescription, setAlertDescription] = useState('');
  const [displayAlert, setDisplayAlert] = useState(false);

  const onCloseAlert = () => {
    setDisplayAlert(false);
    activeUser.current = undefined;
    currentAction.current = undefined;
  };

  const handleSendEmailToSubscriber = () => {
    const currentUser = activeUser.current;
    if (!currentUser) {
      return;
    }
    sendEmail(currentUser.email);
    setDisplayAlert(false);
  };

  const handleDeleteEmail = () => {
    const currentUser = activeUser.current;
    if (!currentUser) {
      return;
    }
    deleteEmail(currentUser.id);
    const data = usersData.data.reduce((all, user) => {
      if (user.id !== currentUser.id) {
        all.push(user);
      }
      return all;
    }, [] as SubscriberTable[]);

    setUsersData(prev => ({ ...prev, data }));
    setDisplayAlert(false);
  };

  const handleAlertAction = () => {
    if (!currentAction.current) {
      return;
    }

    switch (currentAction.current) {
      case 'sendEmail':
        handleSendEmailToSubscriber();
        return;
      case 'deleteEmail':
        handleDeleteEmail();
        return;
      default:
        return;
    }
  };

  const handleSendAccessAlertUser = (row: SubscriberTable) => {
    setAlertDescription(
      `Are you sure you want to send email to this ${userRole}?`,
    );
    setDisplayAlert(true);
    activeUser.current = row;
    currentAction.current = 'sendEmail';
  };

  const handleDeleteAlertUser = (row: SubscriberTable) => {
    setAlertDescription(`Are you sure you want to delete this email?`);
    setDisplayAlert(true);
    activeUser.current = row;
    currentAction.current = 'deleteEmail';
  };

  const renderActions = (row: SubscriberTable) => (
    <div className="flex flex-wrap gap-2">
      <button
        className="bg-primary-main text-white text-[12px] font-semiBold px-2 py-1 rounded-sm"
        onClick={() => handleSendAccessAlertUser(row)}>
        Send Access
      </button>
      <button
        className="bg-[#d63737] text-white text-[12px] font-semiBold px-2 py-1 rounded-sm"
        onClick={() => handleDeleteAlertUser(row)}>
        Delete Mail
      </button>
    </div>
  );

  return (
    <>
      {displayAlert && (
        <Alert
          onClose={onCloseAlert}
          onConfirm={handleAlertAction}
          title={alertDescription}
        />
      )}
      <div className="flex flex-col w-full h-full overflow-auto px-6 scrollbar-hide mb-4">
        <h1 className="text-secondary-main text-[50px] my-6 first-letter:uppercase">
          {userRole}
        </h1>
        <div className="w-full flex flex-wrap justify-around xl:justify-between items-center py-2">
          <div className="flex flex-wrap gap-4 items-center">
            <Dropdown
              defaultValue={filters?.SubscriberTypes}
              placeholder="Type"
              className="w-full min-[480px]:w-[200px]"
              options={SubscriberTypes}
              onSelectOption={handleFilterByType}
            />
            <Button
              className="w-full min-[480px]:w-[200px]"
              text="Reset"
              onClick={handleResetFilters}
            />
          </div>

          <CSVLink
            className="flex md:justify-end w-full min-[480px]:w-[200px] text-center font-semibold text-primary-main justify-center"
            data={usersDownloadedData.data}
            filename="Sporti_Users">
            Download CSV
          </CSVLink>
        </div>
        <StatusHandler isLoading={isLoading} error={error}>
          <>
            <Table
              getActions={renderActions}
              data={usersData?.data}
              columns={usersData?.headers}
              currentPage={currentPage}
            />
            <Pagination
              className="my-4"
              currentPage={currentPage}
              totalPages={totalPages}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </>
        </StatusHandler>
      </div>
    </>
  );
};

export default SubscribedUsers;

import { useEffect, useState, useRef } from 'react';
import useApi from '../../../hooks/useApi';
import { UserResponse } from '../../../models/user';

const useUsers = (params?: Record<string, any>) => {
  const applyFiltersRef = useRef({});
  const [errorMessage, setErrorMessage] = useState<string>();
  const { response, isLoading, launchCall, error } =
    useApi<UserResponse>('admin/v2/users');
  const { launchCall: launchAction } = useApi('users');

  useEffect(() => {
    // Let's make automatically since by the moment
    // there is no other reason to not do it.
    getUsers(params);
  }, []);

  const handleBanUser = async (id: string) => {
    await launchAction({ method: 'PATCH' }, undefined, `users/ban-user/${id}`);
    getUsers();
  };

  const handleMailUser = async (email: string, firstName: string, lastName: string) => {
    await launchAction({ method: 'POST', body: JSON.stringify({ email, firstName, lastName }) }, undefined, `admin/profile/provider/send-incomplete-email`);
    getUsers();
  };

  const handleDeleteUser = (id: string) => {
    launchAction({ method: 'DELETE' }, undefined, `users/${id}`);
  };

  const handleBaneMultipleUser = (ids: string[]) => {
    launchAction(
      { method: 'POST', body: JSON.stringify({ userIds: ids }) },
      undefined,
      'admin/ban-users',
    );
  };
  const handleDeleteMultipleUser = (ids: string[]) => {
    launchAction(
      { method: 'DELETE', body: JSON.stringify({ userIds: ids }) },
      undefined,
      'admin/delete-users',
    );
  };

  const getUsers = (data?: Record<string, any>) => {
    try {
      applyFiltersRef.current = { ...applyFiltersRef.current, ...data };
      launchCall({ method: 'GET' }, { ...params, ...applyFiltersRef.current });
    } catch (err) {
      console.log(err);
    }
  };

  const applyFilters = (
    sorting: { sortBy?: string; search?: string; status?: string },
    reset?: boolean,
  ) => {
    if (reset) {
      applyFiltersRef.current = {};
    }
    getUsers({ ...sorting, skip: 0 });
  };

  const handlePagination = (page: number, offset: number) => {
    const identifier = page - 1;
    getUsers({ skip: identifier * offset });
  };

  const nextPage = () => {
    const nextPage = response?.meta.nextPage;
    if (nextPage) {
      handlePagination(nextPage, response.meta.limit);
    }
  };

  const prevPage = () => {
    const prevPage = response?.meta.prevPage;
    if (prevPage) {
      handlePagination(prevPage, response.meta.limit);
    }
  };

  const sendUserMessage = (message: string, userIds: string[]) => {
    try {
      launchAction(
        {
          method: 'POST',
          body: JSON.stringify({
            userIds: userIds,
            messageText: message,
          }),
        },
        undefined,
        `admin/v2/send-notification-or-email-to-users`,
      );
    } catch (err) {
      console.log(err);
    }
  };

  return {
    getUsers,
    nextPage,
    prevPage,
    banUser: handleBanUser,
    mailUser: handleMailUser,
    deleteUser: handleDeleteUser,
    banMultipleUser: handleBaneMultipleUser,
    deleteMultipleUsers: handleDeleteMultipleUser,
    applyFilters,
    sendUserMessage,
    users: response?.docs,
    totalPages: response?.meta.totalPages,
    currentPage: response?.meta.page,
    isLoading,
    error: errorMessage,
  };
};

export default useUsers;

import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import useApi from '../../../hooks/useApi';

import {
  AdminResponse,
  AdminEmailResponse,
  AdminDeletedEmailResponse,
  AdminChangeStatusResponse,
  ResetPasswordResponse,
} from '../../../models/admin';

const useAdmins = (params?: Record<string, any>) => {
  const applyFiltersRef = useRef({});
  const [errorMessage, setErrorMessage] = useState<string>();
  const { response, isLoading, launchCall, error } = useApi<AdminResponse>(
    'admin/get-all-admin',
  );

  const { launchCall: launchAction, response: emailResponse } =
    useApi<AdminEmailResponse>('subscriber');

  const { launchCall: resetPasswordAction, response: resetPassResponse } =
    useApi<ResetPasswordResponse>('users/v2/admin-forgot-password');

  const { launchCall: deleteEmailAction, response: deletedEmailResponse } =
    useApi<AdminDeletedEmailResponse>('admin/get-all-admin');

  const { launchCall: changeStatusAction, response: ChangeStatusResponse } =
    useApi<AdminChangeStatusResponse>('admin/get-all-admin');

  useEffect(() => {
    getAdmins(params);
  }, []);

  const getAdmins = (data?: Record<string, any>) => {
    try {
      applyFiltersRef.current = { ...applyFiltersRef.current, ...data };
      launchCall({ method: 'GET' }, { ...params, ...applyFiltersRef.current });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (resetPassResponse && resetPassResponse?.statusCode === 201) {
      toast.success(resetPassResponse?.message);
    }
    if (emailResponse && emailResponse?.statusCode === 201) {
      toast.success(emailResponse?.message);
    }
    if (emailResponse && emailResponse?.statusCode === 200) {
      toast.warning(emailResponse?.message);
    }
    if (
      deletedEmailResponse &&
      deletedEmailResponse?.data?.statusCode === 200
    ) {
      toast.success(deletedEmailResponse?.data?.message);
    }
  }, [emailResponse, deletedEmailResponse, resetPassResponse]);

  const handleResetPassword = (email?: string) => {
    try {
      resetPasswordAction({
        method: 'POST',
        body: JSON.stringify({
          email,
        }),
      });
    } catch (err) {
      console.log({ err });
    }
  };

  const applyFilters = (
    sorting: { sortBy?: string; search?: string; status?: string },
    reset?: boolean,
  ) => {
    if (reset) {
      applyFiltersRef.current = {};
    }
    getAdmins({ ...sorting, skip: 0 });
  };

  const handleDeleteSubscribedEmail = (id: string) => {
    try {
      deleteEmailAction(
        { method: 'DELETE' },
        undefined,
        `admin/landing-subscriptions/${id}`,
      );
    } catch (err) {
      console.log({ err });
    }
  };

  const handleChangeStatus = async (userId?: string) => {
    try {
      await changeStatusAction(
        { method: 'GET' },
        undefined,
        `admin/toggle-admin-status/${userId}`,
      );
      getAdmins();
    } catch (err) {
      console.log({ err });
    }
  };

  return {
    getAdmins,
    resetPassword: handleResetPassword,
    deleteEmail: handleDeleteSubscribedEmail,
    changeStatus: handleChangeStatus,
    applyFilters,
    deletedEmailResponse,
    emailResponse,
    admins: response?.data,
    totalPages: response?.meta.totalPages,
    currentPage: response?.meta.page,
    isLoading,
    error: errorMessage,
  };
};

export default useAdmins;

import { format } from 'date-fns';
import { TableColumn } from '../components/ui/Table';
import { User, UserTable } from '../models/user';

const UsersColumns: TableColumn<UserTable>[] = [
  { id: 'serial', label: '#' },
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Name' },
  { id: 'status', label: 'Status' },
  { id: 'bookings', label: 'Bookings' },
  { id: 'sportyPoints', label: 'Sporty Points' },
  { id: 'moneySpent', label: 'Money Spent' },
  { id: 'createdAt', label: 'Date' },
];

const ProviderColumns: TableColumn<UserTable>[] = [
  { id: 'serial', label: '#' },
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Name' },
  { id: 'status', label: 'Status' },
  { id: 'listings', label: 'Listings' },
  { id: 'bookings', label: 'Bookings' },
  { id: 'moneyEarn', label: 'Money Earn' },
  { id: 'createdAt', label: 'Date' },
];

const getTableHeader = (readOnly: boolean, role: string) => {
  const columns = role === 'user' ? UsersColumns : ProviderColumns;
  return readOnly
    ? columns
    : ([
        ...columns,
        { id: 'actions', label: 'Actions' },
      ] as TableColumn<UserTable>[]);
};

export const UsersToUserDataTable = (
  users: User[],
  readOnly = false,
  userRole: string,
) => {
  const data: UserTable[] = users.map(
    (
      {
        _id,
        isVerified,
        firstName,
        lastName,
        email,
        providerVerified,
        isVerificationBadgeEligible,
        sportyPoints,
        bookingCount,
        bookingCountProvider,
        hasActiveBooking,
        hasUpcomingBooking,
        isBanned,
        isOnline,
        isOrganization,
        organizationInfo,
        providerInfo,
        listCount,
        moneyEarn,
        moneySpent,
        verifyShortNumberToken,
        updatePhone,
        twoFa,
        createdAt,
        updatedAt,
      },
      index,
    ) => {
      const values: UserTable = {
        id: _id,
        serial: (index + 1).toString(),
        bookings: userRole === 'provider' ? bookingCountProvider?.toString() : bookingCount?.toString(),
        providerBookigns: bookingCountProvider,
        hasActiveBooking: hasActiveBooking,
        hasUpcomingBooking: hasUpcomingBooking,
        isBanned: isBanned,
        isOnline: isOnline,
        isOrganization: isOrganization,
        organizationInfo: organizationInfo,
        providerInfo: providerInfo,
        moneySpent: '0',
        status: isBanned
          ? 'Banned'
          : isVerificationBadgeEligible || providerVerified
          ? 'Completed'
          : 'Incompleted',
        name: `${firstName || ''} ${lastName || ''}`,
        firstName: firstName,
        lastName: lastName,
        email: email,
        isVerified: isVerified?.toString(),
        signUpOtp: verifyShortNumberToken ?? null,
        phoneUpdateOtp: updatePhone?.otp ?? null,
        twoFaOtp: twoFa?.otp ?? null,
        createdAt: format(
          createdAt ? new Date(createdAt) : new Date(),
          'MM-dd-yyy hh:mm a',
        ),
        updatedAt: format(
          updatedAt ? new Date(updatedAt) : new Date(),
          'MM-dd-yyy hh:mm a',
        ),
      };
      if (userRole === 'user') {
        values.sportyPoints = sportyPoints?.toString();
        values.moneySpent = moneySpent?.toString() || '0';
      }
      if (userRole === 'provider') {
        values.moneyEarn = moneyEarn?.toString() || '0';
        values.listings = listCount?.toString() || '0';
      }
      if (!readOnly) {
        values.actions = 'ACTIONS';
      }
      return values;
    },
  );
  return {
    headers: getTableHeader(readOnly, userRole),
    data,
  };
};

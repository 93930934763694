/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import { useSporforyaContext } from '../../context';
import useCache from '../../hooks/useCache';
import { AUTHENTICATION_TOKEN_KEY } from '../../utils/auth';
import useLogin from './useLogin';

const LoginPage = () => {
  const { token } = useSporforyaContext();
  const navigate = useNavigate();
  const emailRef = useRef('');
  const passwordRef = useRef('');

  const {
    login,
    response,
    isLoading,
    error,
    status,
    rememberMe,
    setRememberMe,
  } = useLogin();
  const { removeCacheData } = useCache();

  useEffect(() => {
    if (response) {
      navigate('/');
    }
  }, [response]);

  useEffect(() => {
    if (token) {
      navigate('/');
    } else {
      removeCacheData(AUTHENTICATION_TOKEN_KEY);
    }
  }, []);

  const onSubmitHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    login(emailRef.current, passwordRef.current);
  };

  const onEmailChange = (value: string) => {
    emailRef.current = value;
  };
  const onPasswordChange = (value: string) => {
    passwordRef.current = value;
  };

  const handleChecked = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <div className="flex w-full h-full">
      <div className="hidden w-[50%] h-[100vh] bg-[url('./assets/login-bg.jpeg')] bg-cover bg-center min-[920px]:block" />
      <div className="w-full h-full flex flex-col justify-center items-center min-[920px]:w-[50%]">
        <div className="block absolute w-full h-full bg-[url('./assets/login-without-bg.png')] bg-cover bg-center -z-10 blur-[2px] min-[920px]:hidden" />
        <form
          className="w-[360px] h-72 flex flex-col justify-between"
          onSubmit={onSubmitHandle}>
          <h1 className="text-5xl text-white min-[920px]:text-black">
            Sign in
          </h1>
          <Input
            required
            className="max-[920px]:border-transparent"
            type="email"
            placeholder="Email Address"
            onInput={onEmailChange}
          />
          <Input
            required
            className="max-[920px]:border-transparent"
            type="password"
            placeholder="Password"
            onInput={onPasswordChange}
          />
          <div className="flex justify-between">
            <div className="flex items-center gap-2 text-sm">
              <input
                type="checkbox"
                id="rememberMe"
                name="rememberMe"
                className="text-primary-main focus:outline-primary-main"
                onChange={handleChecked}
              />
              <label htmlFor="rememberMe">Remember me</label>
            </div>
            <a
              href="/forgot-password"
              className="underline hover:text-primary-main cursor-pointer text-sm">
              Forgot password
            </a>
          </div>
          <Button type="submit" text="Sign in" showLoader={isLoading} />
          <span
            className={`text-xs text-center text-red-500 font-bold ${
              !error && 'opacity-0'
            }`}>
            {status === 401
              ? 'Admin deactivated. Please contact owner.'
              : 'Your username or password is wrong, please try again'}
          </span>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;

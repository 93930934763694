import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import useApi from '../../../hooks/useApi';
import {
  SubscriberDeletedEmailResponse,
  SubscriberEmailResponse,
} from '../../../models/subscriber';
import { SubscribedUserResponse } from '../../../models/user';

const useSubscribedUsers = () => {
  const applyFiltersRef = useRef({});
  const [errorMessage, setErrorMessage] = useState<string>();
  const { response, isLoading, launchCall, error } = useApi<SubscribedUserResponse>(
    'admin/landing-subscriptions',
  );

  const { launchCall: launchAction, response: emailResponse } =
    useApi<SubscriberEmailResponse>('subscriber');

  const { launchCall: deleteEmailAction, response: deletedEmailResponse } =
    useApi<SubscriberDeletedEmailResponse>('admin/landing-subscriptions');

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    try {
      launchCall({ method: 'GET' }, { ...applyFiltersRef.current });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (emailResponse && emailResponse?.statusCode === 201) {
      toast.success(emailResponse?.message);
    }
    if (emailResponse && emailResponse?.statusCode === 200) {
      toast.warning(emailResponse?.message);
    }
    if (
      deletedEmailResponse &&
      deletedEmailResponse?.data?.statusCode === 200
    ) {
      toast.success(deletedEmailResponse?.data?.message);
    }
  }, [emailResponse, deletedEmailResponse]);

  const handleEmailSubscriber = (email: string) => {
    try {
      launchAction(
        { method: 'GET' },
        undefined,
        `users/invite-early-access/${email}`,
      );
    } catch (err) {
      console.log({ err });
    }
  };

  const handleDeleteSubscribedEmail = (id: string) => {
    try {
      deleteEmailAction(
        { method: 'DELETE' },
        undefined,
        `admin/landing-subscriptions/${id}`,
      );
    } catch (err) {
      console.log({ err });
    }
  };

  return {
    getUsers,
    sendEmail: handleEmailSubscriber,
    deleteEmail: handleDeleteSubscribedEmail,
    deletedEmailResponse,
    emailResponse,
    users: response?.data,
    isLoading,
    error: errorMessage,
  };
};

export default useSubscribedUsers;

import { format } from 'date-fns';
import { TableColumn } from '../components/ui/Table';
import { Listing, ListingTable } from '../models/listings';

const ListingColumns: TableColumn<ListingTable>[] = [
  { id: 'serial', label: '#' },
  { id: 'id', label: 'ID' },
  { id: 'title', label: 'Title' },
  { id: 'provider', label: 'Provider' },
  { id: 'type', label: 'Type' },
  { id: 'rating', label: 'Rating' },
  { id: 'status', label: 'Status' },
  { id: 'review', label: 'Review' },
  { id: 'createdAt', label: 'Date' },
];

const getTableHeader = (readOnly: boolean) => {
  return readOnly
    ? ListingColumns
    : ([
        ...ListingColumns,
        { id: 'actions', label: 'Actions' },
      ] as TableColumn<ListingTable>[]);
};

export const ListingToListingDataTable = (
  listings: Listing[],
  readOnly = false,
) => {
  const data: ListingTable[] = listings.map(
    (
      {
        _id,
        avgRating,
        listingType,
        status,
        title,
        provider,
        isApproved,
        createdAt,
      },
      index,
    ) => {
      const values: ListingTable = {
        id: _id,
        serial: (index + 1).toString(),
        rating: avgRating.toString(),
        type: listingType,
        provider: `${provider?.[0]?.firstName || ''} ${
          provider?.[0]?.lastName || ''
        } `,
        providerEmail: provider?.[0]?.email ?? '',
        status:
          provider?.length === 0
            ? 'Account Closed'
            : provider?.[0]?.status === 'hibernated'
            ? 'Hibernated'
            : provider?.[0]?.isBanned
            ? 'Banned'
            : provider?.[0]?.isDeleted
            ? 'Deleted'
            : status === 'under_review'
            ? 'Under Review'
            : status,
        review: isApproved ? 'Approved' : 'Required',
        title,
        createdAt: format(
          createdAt ? new Date(createdAt) : new Date(),
          'MM-dd-yyy hh:mm a',
        ),
      };
      if (!readOnly) {
        values.actions = 'ACTIONS';
      }
      return values;
    },
  );
  return {
    headers: getTableHeader(readOnly),
    data,
  };
};

import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import Dropdown, { DropdownOption } from '../../components/Dropdown';
import Pagination from '../../components/Pagination';
import StatusHandler from '../../components/StatusHandler';
import Button from '../../components/ui/Button';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as ViewIcon } from '../../assets/eye-fill.svg';
import Table, { TableColumn } from '../../components/ui/Table';
import { BookingTable } from '../../models/bookings';
import { BookingToBookingTable } from '../../utils/bookings';
import useBookings from './useBookings';
import { useNavigate } from 'react-router-dom';
import { NotificationDialog } from '../../components/NotificationDialog';

const BookingStatus = [
  {
    id: 'active',
    value: 'Ongoing',
  },
  {
    id: 'upcoming',
    value: 'Upcoming',
  },
  {
    id: 'past',
    value: 'Completed',
  },
  {
    id: 'dispute',
    value: 'Under Review',
  },
  {
    id: 'cancel',
    value: 'Cancelled',
  },
];

const DateOrder = [
  {
    id: 'desc',
    value: 'Newest first',
  },
  {
    id: 'asc',
    value: 'Oldest first',
  },
];

const Bookings = () => {
  const [selectedUsers, setSelectedUsers] = useState<BookingTable[]>([]);
  const navigate = useNavigate();
  const activeBooking = useRef<BookingTable>();
  const [toggleDialog, setToggleDialog] = useState(false);
  const [filters, setSelectedFilters] = useState<{
    status?: DropdownOption;
    order?: DropdownOption;
  }>({});
  const [bookingsData, setBookingsData] = useState<{
    headers: TableColumn<BookingTable>[];
    data: BookingTable[];
  }>({ headers: [], data: [] });
  const {
    bookings,
    isLoading,
    currentPage,
    totalPages,
    cancelBooking,
    applyFilters,
    nextPage,
    prevPage,
    error,
  } = useBookings({ limit: 20 });

  useEffect(() => {
    if (bookings) {
      setBookingsData(BookingToBookingTable(bookings));
    }
  }, [bookings]);

  const openCancelBookingDialog = (row: BookingTable) => {
    activeBooking.current = row;
    handleToggleDialog();
  };

  const handleCancelBooking = (message: string) => {
    if (!activeBooking.current) return;
    const currentId = activeBooking.current.id;
    cancelBooking([activeBooking.current?.id], message);
    const data = bookingsData.data.reduce((all, booking) => {
      if (booking.id !== currentId) {
        all.push(booking);
      } else {
        all.push({ ...booking, status: 'decline' });
      }
      return all;
    }, [] as BookingTable[]);

    activeBooking.current = undefined;
    setBookingsData(prev => ({ ...prev, data }));
    handleToggleDialog();
  };

  const handleViewBooking = (row: BookingTable) => {
    const rowId = row.id;
    if (!bookings) {
      return;
    }

    const bookingData = bookings.find(dataItem => dataItem._id === rowId);
    navigate(rowId, { state: bookingData });
  };

  const renderActions = (row: BookingTable) => (
    <>
      {row.status !== 'decline' && (
        <DeleteIcon
          title="Cancel booking"
          className="mr-2 fill-[#d63737] cursor-pointer"
          onClick={() => openCancelBookingDialog(row)}
        />
      )}
      <ViewIcon
        title="Booking details"
        className="fill-[#ffc904] cursor-pointer"
        onClick={() => handleViewBooking(row)}
      />
    </>
  );

  const handleStatusChange = (value: DropdownOption) => {
    setSelectedFilters(prev => ({ ...prev, status: value }));
    if (value?.id === 'dispute') {
      applyFilters({ status: [value.id, 'partial-cancel'] });
    } else {
      applyFilters({ status: [value.id] });
    }
  };

  const handleSortByDate = (value: DropdownOption) => {
    setSelectedFilters(prev => ({ ...prev, order: value }));
    applyFilters({ order: value.id });
  };

  const handleResetFilters = () => {
    setSelectedFilters({});
    applyFilters({}, true);
  };

  const handleToggleDialog = () => {
    setToggleDialog(prev => !prev);
  };

  const handleRowSelection = (selected: boolean, row: BookingTable) => {
    if (selected) {
      setSelectedUsers(prev => [...prev, row]);
      return;
    }

    setSelectedUsers(users => users.filter(user => user.id !== row.id));
  };

  const handleCancelMultipleBooking = () => {
    handleToggleDialog();
  };

  const handleCancelMultipleBookings = (message: string) => {
    const bookingIds = selectedUsers?.map(user => user?.id);
    cancelBooking(bookingIds, message);
    const data = bookingsData.data.reduce((all, booking) => {
      if (!bookingIds.includes(booking?.id)) {
        all.push(booking);
      } else {
        all.push({ ...booking, status: 'decline' });
      }
      return all;
    }, [] as BookingTable[]);
    setBookingsData(prev => ({ ...prev, data }));
    handleToggleDialog();
  };

  return (
    <>
      {toggleDialog && (
        <NotificationDialog
          title="Cancel booking"
          description="Provide more information about the reason behind the booking cancellation"
          onClose={handleToggleDialog}
          buttonText="Cancel Booking"
          placeholder="Reason"
          onSubmit={handleCancelBooking}
        />
      )}
      {toggleDialog && selectedUsers?.length > 1 && (
        <NotificationDialog
          title="Cancel booking"
          description="Provide more information about the reason behind the booking cancellation"
          onClose={handleToggleDialog}
          buttonText="Cancel Booking"
          placeholder="Reason"
          onSubmit={handleCancelMultipleBookings}
        />
      )}
      <div className="flex flex-col w-full h-full overflow-auto px-6 scrollbar-hide">
        <h1 className="text-secondary-main text-[50px] my-6">Bookings</h1>
        <div className="w-full flex flex-wrap gap-6 items-center mb-6">
          <div className="flex flex-wrap gap-6 items-center w-full min-[480px]:w-auto">
            <Dropdown
              placeholder="Status"
              className="w-full min-[480px]:w-[200px]"
              defaultValue={filters.status}
              options={BookingStatus}
              onSelectOption={handleStatusChange}
            />
          </div>
          <div className="flex flex-wrap gap-6 items-center w-full min-[480px]:w-auto">
            <Dropdown
              placeholder="Date"
              className="w-full min-[480px]:w-[200px]"
              defaultValue={filters.order}
              options={DateOrder}
              onSelectOption={handleSortByDate}
            />
          </div>
          <div className="flex flex-wrap gap-6 items-center w-full min-[480px]:w-auto">
            <Button
              className="w-full min-[480px]:w-[200px]"
              text="Reset"
              onClick={handleResetFilters}
            />
            <CSVLink
              className="w-full min-[480px]:w-[200px] text-center font-semibold text-primary-main justify-center"
              data={bookingsData.data}
              filename="Sporti_Bookings">
              Download CSV
            </CSVLink>
          </div>
        </div>
        {selectedUsers.length > 1 && (
          <div className="flex justify-end">
            <button
              className="flex items-center p-2 mr-2"
              onClick={handleCancelMultipleBooking}>
              <DeleteIcon className="fill-[#d63737] mr-1" />
              <span className="text-[#d63737] font-semibold">{`Cancel Booking`}</span>
            </button>
          </div>
        )}
        <StatusHandler isLoading={isLoading} error={error}>
          <>
            <Table
              selectable
              data={bookingsData?.data}
              columns={bookingsData?.headers}
              selectedRows={selectedUsers}
              onRowSelection={handleRowSelection}
              getActions={renderActions}
              currentPage={currentPage}
            />
            <Pagination
              className="my-4"
              currentPage={currentPage}
              totalPages={totalPages}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </>
        </StatusHandler>
      </div>
    </>
  );
};

export default Bookings;

import Loader from '../../Loader';
type Props = {
  text: string;
  styleType?: 'primary' | 'secondary';
  type?: 'submit' | 'button' | 'reset';
  className?: string;
  disabled?: boolean;
  showLoader?: boolean;
  onClick?: () => void;
};

const ButtonStlyes = {
  primary: 'bg-primary-main text-white hover:bg-primary-dark',
  secondary:
    'text-primary-main bg-white border border-primary-main hover:bg-primary-main hover:border-transparent hover:text-white',
};

export const Button = ({
  text,
  styleType = 'primary',
  type = 'button',
  className,
  disabled,
  showLoader,
  onClick,
}: Props) => {
  return (
    <button
      className={`w-full rounded-md py-2 px-3 flex items-center justify-center gap-2  ${ButtonStlyes[styleType]} ${className}`}
      type={type}
      disabled={disabled}
      onClick={onClick}>
      {text}
      <span>{showLoader && <Loader svgStyle="fill-white" />}</span>
    </button>
  );
};
export default Button;

import { useCallback, useMemo } from 'react';
import UserProfilePhoto from './UserProfilePhoto';
import { ReactComponent as QuestionCircleIcon } from '../../assets/question-circle.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/check-circle.svg';
import { ReactComponent as CrossCircleIcon } from '../../assets/cross-circle.svg';
import { ReactComponent as BannedIcon } from '../../assets/banned-icon.svg';
import { ReactComponent as ShieldCheckIcon } from '../../assets/shield-check.svg';
import { ReactComponent as ShieldExclamationIcon } from '../../assets/shield-exclamation.svg';
import { User } from '../../models/user';

type UserDetailProps = {
  userData: User;
  role: string;
};

const UserDetail = ({ userData, role }: UserDetailProps) => {
  const getParsedDate = useCallback((date: string) => {
    const dateTime = new Date(date);
    return dateTime.toLocaleString('en-US');
  }, []);

  const userFullName = useMemo(() => {
    if (!userData) {
      return null;
    }
    return `${userData.firstName} ${userData.lastName}`;
  }, [userData]);

  const formattedAmount = useMemo(() => {
    if (!userData) {
      return null;
    }
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(userData.moneySpent);
  }, [userData]);

  console.log({ userData });

  return (
    <div className="mx-auto">
      <div>
        <p className="text-[10px] border-b font-bold uppercase text-[#909193]">
          Basic Info
        </p>
        <div className="flex gap-4 my-4">
          <UserProfilePhoto
            profileUrl={
              userData?.isOrganization
                ? userData?.organizationInfo?.avatar
                : userData?.providerInfo?.avatar
            }
            firstName={userData?.firstName}
          />
          <div>
            <p className="text-lg capitalize font-semibold">{userFullName}</p>
            <p className="text-sm">{userData?.email}</p>
            <p className="text-sm">{userData?.phone}</p>
          </div>
        </div>
      </div>
      <div>
        <p className="text-[10px] border-b font-bold uppercase text-[#909193]">
          Profile
        </p>
        <div className="text-md my-4">
          <div className="flex mb-2 items-center">
            <p className="basis-[160px] flex-shrink-[0]">Account Created:</p>
            <p>{getParsedDate(userData?.createdAt)}</p>
          </div>
          <div className="flex mb-2 items-center">
            <p className="basis-[160px] flex-shrink-[0]">Last Updated:</p>
            <p>{getParsedDate(userData?.updatedAt)}</p>
          </div>
          <div className="flex mb-2 items-center">
            <p className="basis-[160px] flex-shrink-[0]">Roles</p>
            <p className="capitalize">{role.substring(0, role.length - 1)}</p>
          </div>
          {role === 'providers' && (
            <div className="flex mb-2 items-center">
              <p className="basis-[160px] flex-shrink-[0]">Provider Types:</p>
              <p>{userData?.isOrganization ? 'Organization' : 'Individual'}</p>
            </div>
          )}

          <div className="flex mb-2 items-center">
            <p className="basis-[160px] flex-shrink-[0]">Banned</p>
            <p className="flex gap-1">
              {userData?.isBanned ? (
                <BannedIcon className="text-red-600 w-[20px]" />
              ) : (
                <CrossCircleIcon className="text-red-600 w-[20px]" />
              )}
              <span className="text-success-main">
                {userData?.isBanned ? 'Banned' : 'No'}
              </span>
            </p>
          </div>
          <div className="flex mb-2 items-center">
            <p className="basis-[160px] flex-shrink-[0]">Online:</p>
            <p className="flex gap-1">
              {userData?.isOnline ? (
                <CheckCircleIcon className="text-green-600 w-[20px]" />
              ) : (
                <CrossCircleIcon className="text-red-600 w-[20px]" />
              )}
              <span className="text-success-main">
                {userData?.isOnline ? 'Yes' : 'No'}
              </span>
            </p>
          </div>
          <div className="flex mb-2 items-center">
            <p className="basis-[160px] flex-shrink-[0]">
              Varification Badge Eligible:
            </p>
            <p className="flex gap-1">
              {userData?.isVerificationBadgeEligible ? (
                <ShieldCheckIcon className="text-green-600 w-[20px]" />
              ) : (
                <ShieldExclamationIcon className="text-red-600 w-[20px]" />
              )}
              <span className="text-success-main">
                {userData?.isVerificationBadgeEligible ? 'Yes' : 'No'}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div>
        <p className="text-[10px] border-b font-bold uppercase text-[#909193]">
          Activity
        </p>
        <div className="flex gap-8 sm:gap-6 mt-3">
          <div>
            <p className="text-xs text-gray-500">Bookings</p>
            <p className="text-2xl font-semibold">{userData?.bookingCount}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Sporty Points</p>
            <p className="text-2xl font-semibold">{userData?.sportyPoints}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Money Spent</p>
            <p className="text-2xl font-semibold">{formattedAmount}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSporforyaContext } from '../../../context';
import useApi from '../../../hooks/useApi';
import { MetricsResponse } from '../../../models/dashboard';

const useDashboard = (params?: Record<string, any>) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { response, isLoading, launchCall, error, status } = useApi<{
    data: MetricsResponse;
  }>('admin/dashboard');
  const { setToken } = useSporforyaContext();
  const navigate = useNavigate();

  useEffect(() => {
    // Let's make automatically since by the moment
    // there is no other reason to not do it.
    getDashboardMetrics(params);
  }, []);

  useEffect(() => {
    if (error) {
      // we redirect the user if they are not authenticated.
      if (status === 401) {
        setToken(undefined);
        navigate('/login');
      } else {
        setErrorMessage('Something went wrong, please try again later.');
      }
    }
  }, [error]);

  const applyFilters = (filter: { monthNumber: string }) => {
    getDashboardMetrics(filter);
  };

  const getDashboardMetrics = (params?: Record<string, string | number>) => {
    try {
      setErrorMessage(undefined);
      launchCall({ method: 'GET' }, params);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    applyFilters,
    metrics: response?.data,
    isLoading,
    error: errorMessage,
  };
};

export default useDashboard;

import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import { ReactComponent as ViewIcon } from '../../assets/eye-fill.svg';
import { ReactComponent as BanIcon } from '../../assets/ban.svg';
import { ReactComponent as MessageIcon } from '../../assets/message.svg';
import { ReactComponent as OtpIcon } from '../../assets/otp-icon.svg';
import { ReactComponent as MailIcon } from '../../assets/mail.svg';
import Dropdown, { DropdownOption } from '../../components/Dropdown';
import Pagination from '../../components/Pagination';
import StatusHandler from '../../components/StatusHandler';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import Table, { TableColumn } from '../../components/ui/Table';
import { UserTable } from '../../models/user';
import { UsersToUserDataTable } from '../../utils/users';
import useUsers from './useUsers';
import Alert from '../../components/Alert';
import { NotificationDialog } from '../../components/NotificationDialog';
import Modal from '../../components/Modal';
import { toast } from 'react-toastify';

const DateOrder = [
  {
    id: 'desc',
    value: 'Newest first',
  },
  {
    id: 'asc',
    value: 'Oldest first',
  },
];

const Status = [
  {
    id: 'complete',
    value: 'Completed',
  },
  {
    id: 'incomplete',
    value: 'In-completed',
  },
  {
    id: 'banned',
    value: 'Banned',
  },
];

type Props = {
  userRole?: string;
};

const Users = ({ userRole = 'user' }: Props) => {
  const currentAction = useRef<string>();
  const activeUser = useRef<UserTable>();
  const [toggleDialog, setToggleDialog] = useState(false);
  const [filters, setSelectedFilters] = useState<{
    sortBy?: DropdownOption;
    status?: DropdownOption;
    search?: string;
  }>({ sortBy: DateOrder[0] });
  const [alertDescription, setAlertDescription] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<UserTable[]>([]);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [usersData, setUsersData] = useState<{
    headers: TableColumn<UserTable>[];
    data: UserTable[];
  }>({ headers: [], data: [] });
  const {
    getUsers,
    users,
    isLoading,
    currentPage,
    totalPages,
    nextPage,
    prevPage,
    mailUser,
    banUser,
    applyFilters,
    banMultipleUser,
    deleteMultipleUsers,
    deleteUser,
    sendUserMessage,
    error,
  } = useUsers({ role: userRole, limit: 20 });
  const navigate = useNavigate();

  useEffect(() => {
    if (users) {
      setUsersData(UsersToUserDataTable(users, false, userRole));
    }
  }, [users]);

  const onCloseAlert = () => {
    setDisplayAlert(false);
    activeUser.current = undefined;
    currentAction.current = undefined;
  };

  const onCloseModal = () => {
    setDisplayModal(false);
    activeUser.current = undefined;
    currentAction.current = undefined;
  };

  const handleAlertAction = () => {
    if (!currentAction.current) {
      return;
    }

    switch (currentAction.current) {
      case 'delete':
        handleDeleteUser();
        return;
      case 'ban':
        handleBanUser();
        return;
      case 'mail':
        handleMailUser();
        return;
      case 'multi-ban':
        handleMultiBanUser();
        return;
      case 'multi-delete':
        handleMultiDeleteUser();
        return;
      default:
        return;
    }
  };

  const handleDeleteUser = () => {
    const currentUser = activeUser.current;
    if (!currentUser) {
      return;
    }
    deleteUser(currentUser.id);
    const data = usersData.data.reduce((all, user) => {
      if (user.id !== currentUser.id) {
        all.push(user);
      }
      return all;
    }, [] as UserTable[]);

    setUsersData(prev => ({ ...prev, data }));
    setDisplayAlert(false);
  };

  const handleMultiDeleteUser = () => {
    deleteMultipleUsers(selectedUsers.map(user => user.id));
    const data = usersData.data.reduce((all, user) => {
      if (!selectedUsers.find(({ id }) => id === user.id)) {
        all.push(user);
      }
      return all;
    }, [] as UserTable[]);
    setSelectedUsers([]);
    setUsersData(prev => ({ ...prev, data }));
    setDisplayAlert(false);
  };

  const handleMultiBanUser = () => {
    const userWithActiveBooking = selectedUsers.filter(
      user => user?.hasActiveBooking === true,
    );
    if (userWithActiveBooking?.length > 0) {
      navigate('/bookings');
    } else {
      banMultipleUser(selectedUsers.map(user => user.id));
      const data = usersData.data.reduce((all, user) => {
        if (!selectedUsers.find(({ id }) => id === user.id)) {
          all.push(user);
        }
        return all;
      }, [] as UserTable[]);
      setSelectedUsers([]);
      setUsersData(prev => ({ ...prev, data }));
      setDisplayAlert(false);
    }
  };

  const handleBanUser = () => {
    const currentUser = activeUser.current;
    if (!currentUser) {
      return;
    }
    if (currentUser?.hasActiveBooking || currentUser?.hasUpcomingBooking) {
      navigate(`/bookings`);
    } else {
      banUser(currentUser.id);

      const data = usersData.data.reduce((all, user) => {
        if (user.id !== currentUser.id) {
          all.push(user);
        }
        return all;
      }, [] as UserTable[]);

      setUsersData(prev => ({ ...prev, data }));
      getUsers();
      setDisplayAlert(false);
    }
  };

  const handleMailUser = () => {
    const currentUser = activeUser.current;
    if (!currentUser) {
      return;
    }
    const { email, firstName, lastName } = currentUser;
    mailUser(email, firstName, lastName);
    toast.success('Email has been sent to the provider');
    getUsers();
    setDisplayAlert(false);
  };

  const handleSortByDate = (option: DropdownOption) => {
    setSelectedFilters(prev => ({ ...prev, sortBy: option }));
    applyFilters({ sortBy: option.id });
  };

  const handleSortByStatus = (option: DropdownOption) => {
    setSelectedFilters(prev => ({ ...prev, status: option }));
    applyFilters({ status: option.id });
  };

  const handleDeleteMultipleAlertUser = () => {
    setAlertDescription(`Are you sure you want to delete these ${userRole}s?`);
    setDisplayAlert(true);
    currentAction.current = 'multi-delete';
  };

  const handleBanMultipleAlertUser = () => {
    const userWithActiveBooking = selectedUsers.filter(
      user =>
        user?.hasActiveBooking === true || user?.hasUpcomingBooking === true,
    );
    setAlertDescription(
      `${
        userWithActiveBooking?.length > 0
          ? 'These user has active booking. Please cancel those first. Do you want to proceed to booking page?'
          : `Are you sure you want to ban these ${userRole}?`
      }`,
    );
    setDisplayAlert(true);
    currentAction.current = 'multi-ban';
  };

  const handleDeleteAlertUser = (row: UserTable) => {
    setAlertDescription(`Are you sure you want to delete this ${userRole}?`);
    setDisplayAlert(true);
    activeUser.current = row;
    currentAction.current = 'delete';
  };
  const handleBanAlertUser = (row: UserTable) => {
    if (row?.status === 'Banned') {
      return;
    }
    setAlertDescription(
      `${
        row?.hasActiveBooking || row?.hasUpcomingBooking
          ? 'This user has active booking. Please cancel those first. Do you want to proceed to booking page?'
          : `Are you sure you want to ban this ${userRole}?`
      }`,
    );
    setDisplayAlert(true);
    activeUser.current = row;
    currentAction.current = 'ban';
  };

  const handleMailAlertUser = (row: UserTable) => {
    if (row?.status !== 'Incompleted') {
      return;
    }
    setAlertDescription('Are you sure you want to send the Mail?');
    setDisplayAlert(true);
    activeUser.current = row;
    currentAction.current = 'mail';
  };

  const handleViewOtp = (row: UserTable) => {
    setModalDescription(
      `Sign up Otp: ${row?.signUpOtp ?? 'N/A'}. Phone Update Otp: ${
        row?.phoneUpdateOtp ?? row?.signUpOtp ?? 'N/A' // This needs to removed after BE solve the update phone otp issue
      }. Two Factor Otp: ${row?.twoFaOtp ?? 'N/A'}`,
    );
    setDisplayModal(true);
    activeUser.current = row;
    currentAction.current = 'otp';
  };

  const handleMessage = (row: UserTable) => {
    activeUser.current = row;
    handleToggleDialog();
  };

  const renderActions = (row: UserTable) => (
    <>
      <MailIcon
        title="Email User"
        className={`mr-2 ${
          row?.status !== 'Incompleted'
            ? 'disabled fill-[#939090] cursor-not-allowed'
            : 'block fill-primary-main cursor-pointer'
        }`}
        onClick={() => handleMailAlertUser(row)}
      />
      <DeleteIcon
        title="Delete User"
        className="mr-2 fill-[#d63737] cursor-pointer"
        onClick={() => handleDeleteAlertUser(row)}
      />
      <BanIcon
        title="Ban User"
        className={`mr-2 ${
          row?.status === 'Banned'
            ? 'disabled fill-[#939090] cursor-not-allowed'
            : 'block fill-[#d63737] cursor-pointer'
        }`}
        onClick={() => handleBanAlertUser(row)}
      />
      <MessageIcon
        title="Message User"
        className="mr-2 fill-[#37a4d6] cursor-pointer"
        onClick={() => handleMessage(row)}
      />
      {/* <PencilIcon
        title="Edit User"
        className="fill-[#ffc904] cursor-pointer"
        onClick={() => onEditListing(row.id)}
      /> */}
      <ViewIcon
        title="View User"
        className="fill-[#ffc904] cursor-pointer"
        onClick={() => handleViewUser(row.id)}
      />
      <OtpIcon
        title="View Otp"
        className="fill-primary-main cursor-pointer w-5 pl-1"
        onClick={() => handleViewOtp(row)}
      />
    </>
  );

  const handleSearchChange = (value: string) => {
    setSelectedFilters(prev => ({ ...prev, search: value }));
    applyFilters({ search: value });
  };

  const handleRowSelection = (selected: boolean, row: UserTable) => {
    if (selected) {
      setSelectedUsers(prev => [...prev, row]);
      return;
    }

    setSelectedUsers(users => users.filter(user => user.id !== row.id));
  };

  const onEditListing = (rowId: string) => {
    if (!users) {
      return;
    }

    const userData = users.find(dataItem => dataItem._id === rowId);
    navigate(`edit/${rowId}`, { state: { data: userData, role: userRole } });
  };

  const handleViewUser = (rowId: string) => {
    if (!users) {
      return;
    }

    const userData = users.find(dataItem => dataItem._id === rowId);
    navigate(rowId, { state: userData });
  };

  const handleToggleDialog = () => {
    setToggleDialog(prev => !prev);
  };

  const handleSendNotification = (message: string) => {
    handleToggleDialog();
    const users = !!activeUser.current ? [activeUser.current] : selectedUsers;
    activeUser.current = undefined;
    setSelectedUsers([]);
    sendUserMessage(
      message,
      users.map(user => user.id),
    );
  };

  const handleResetFilters = () => {
    setSelectedFilters({ sortBy: DateOrder[0] });
    applyFilters({ sortBy: DateOrder[0].id }, true);
  };

  return (
    <>
      {displayAlert && (
        <Alert
          onClose={onCloseAlert}
          onConfirm={handleAlertAction}
          title={alertDescription}
        />
      )}

      {displayModal && <Modal onClose={onCloseModal} body={modalDescription} />}

      {toggleDialog && (
        <NotificationDialog
          onClose={handleToggleDialog}
          placeholder="Message to user"
          onSubmit={handleSendNotification}
        />
      )}
      <div className="flex flex-col w-full h-full overflow-auto px-6 scrollbar-hide">
        <h1 className="text-secondary-main text-[50px] my-6 first-letter:uppercase">
          {userRole}
        </h1>
        <div className="w-full flex flex-wrap md:flex-none gap-2 items-center mb-6">
          <Input
            className="w-full min-[480px]:w-[300px]"
            placeholder="Search name or ID"
            value={filters.search}
            onInput={handleSearchChange}
          />
          <div className="flex gap-2 items-center w-full min-[480px]:w-auto">
            <Dropdown
              placeholder="Date"
              className="w-full min-[480px]:w-[200px]"
              defaultValue={filters.sortBy}
              options={DateOrder}
              onSelectOption={handleSortByDate}
            />
            <Dropdown
              placeholder="Status"
              className={`w-full min-[480px]:w-[200px]`}
              defaultValue={filters.status}
              options={Status}
              onSelectOption={handleSortByStatus}
            />
          </div>
          <div className="flex flex-wrap justify-between items-center w-full min-[480px]:w-auto">
            <Button
              className="w-full min-[480px]:w-[200px]"
              text="Reset"
              onClick={handleResetFilters}
            />
            <CSVLink
              className="w-full min-[480px]:w-[200px] text-center font-semibold text-primary-main justify-center"
              data={usersData.data}
              filename="Sporti_Users">
              Download CSV
            </CSVLink>
          </div>
        </div>
        {selectedUsers.length > 1 && (
          <div className="flex justify-end">
            <button
              className="flex items-center p-2 mr-2"
              onClick={handleDeleteMultipleAlertUser}>
              <DeleteIcon className="fill-[#d63737] mr-1" />
              <span className="text-[#d63737] font-semibold">{`Delete ${userRole}s`}</span>
            </button>
            <button
              className="flex p-2 items-center"
              onClick={handleBanMultipleAlertUser}>
              <BanIcon className="fill-[#d63737] mr-1" />
              <span className="text-[#d63737] font-semibold">{`Ban ${userRole}s`}</span>
            </button>
            <button
              className="flex p-2 items-center"
              onClick={handleToggleDialog}>
              <MessageIcon className="fill-[#37a4d6] mr-1" />
              <span className="text-[#37a4d6] font-semibold">{`Notify ${userRole}s`}</span>
            </button>
          </div>
        )}
        <StatusHandler isLoading={isLoading} error={error}>
          <>
            <Table
              selectable
              getActions={renderActions}
              selectedRows={selectedUsers}
              onRowSelection={handleRowSelection}
              data={usersData?.data}
              columns={usersData?.headers}
              currentPage={currentPage}
            />
            <Pagination
              className="my-4"
              currentPage={currentPage}
              totalPages={totalPages}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </>
        </StatusHandler>
      </div>
    </>
  );
};

export default Users;

import Slider, { Settings } from 'react-slick';
import { ReactComponent as ArrowLeft } from '../../assets/chevron-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/chevron-right.svg';

interface ISlide {
  slides: string[];
}

const Slide = ({ slides }: ISlide) => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowRight fill="#fff" direction="next" />,
    prevArrow: <ArrowLeft fill="#fff" direction="prev" />,
    className: 'list-detail-slider',
  };
  const placeholderImage =
    'https://placehold.jp/30/eeeeee/cccccc/770x350.png?text=Image+Not+Found';
  const imagesHostUrl = process.env.REACT_APP_IMAGES_HOST_URL;

  const onImageError = (element: HTMLImageElement) => {
    element.src = placeholderImage;
  };

  return (
    <div className="slider-container rounded-[7px] slider">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className="slick-slide rounded-[7px] overflow-hidden">
            <div className="h-[230px] md:h-[350px] rounded-[7px] overflow-hidden relative">
              <div className="bg-[#000] bg-opacity-20 absolute top-0 left-0 w-full h-full z-10" />
              <img
                src={`${imagesHostUrl}${slide}`}
                className="w-full h-full object-cover"
                alt=""
                onError={e => onImageError(e.currentTarget)}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Slide;
